import { createRouter, createWebHistory, type RouteLocationNormalized } from 'vue-router';
import MainRoutes from './MainRoutes';
import AuthRoutes from './AuthRoutes';
import CampaignRoutes from './CampaignRoutes';
import MidiaRouter from './MidiaRouter';
import SubscriptionRouter from './SubscriptionRouter';
import UsersRoute from './UsersRoute';
import AdminRoute from './AdminRoutes';
import { useAuthStore } from '@/stores/auth';
import { useAdminStore } from '@/stores/admin';


export const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/:pathMatch(.*)*',
      component: () => import('@/views/pages/maintenance/error/Error404Page.vue')
    },
    MainRoutes,
    AuthRoutes,
    CampaignRoutes,
    MidiaRouter,
    SubscriptionRouter,
    UsersRoute,
    AdminRoute
  ]
});

interface User {
  // Define the properties and their types for the user data here
  // For example:
  id: number;
  name: string;
}

export function isAuthRequired(to: RouteLocationNormalized): boolean {
  return to.matched.some((record) => record.meta.requiresAuth)
}


router.beforeEach(async (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const auth: any = useAuthStore();
  const admin: any = useAdminStore();

  // By default is not admin
  admin.isAdminPage = false;

  if (isAuthRequired(to)) {

    if (authRequired && !auth.user) {
      auth.returnUrl = to.fullPath;
      return next('/login');
    } else {


      if (!auth.isTokenValid()) { return next('/login') }

      if (auth.user && auth.user.usingTemporaryPassword === true) {

        if (to.path !== '/alterar-senha/' + auth.user.id) {
          return next(('/alterar-senha/' + auth.user.id));
        }
      }

      if (to.matched.some((record) => record.meta.requiresRole && !auth.hasAnyRole(record.meta.requiresRole))) {
        return next('/');
      }

      admin.isAdminPage = to.matched.some((record) => record.meta.isAdminPanel);

      next();
    }

  } else {

    next();
  }
});
