const MainRoutes = {
    path: '/assinatura',
    meta: {
        requiresAuth: true
    },
    component: () => import('@/layouts/full/FullLayout.vue'),
    children: [
        {
            name: 'Subscription',
            path: '/assinatura',
            children: [
                {
                    name: 'SubscriptionHome',
                    path: '',
                    component: () => import('@/views/subscription/SubscriptionHome.vue'),
                },
            ]
        }
    ]
};

export default MainRoutes;
