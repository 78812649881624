export const ENDPOINTS = {
  MISC: {
    HOME: '',
    PING: 'ping'
  },
  AUTH: {
    LOGIN: 'auth',
    REFRESH_TOKEN: 'auth/refresh',
    RESET: 'auth/reset',
    REGISTER: 'auth/register',
    CHANGE_PASSWORD: 'auth/change-password'
  },
  AUTH_USER_ROLES: {
    GET_ALL: 'auth-user-roles',
    DELETE: 'auth-user-roles',
    CREATE: 'auth-user-roles'
  },
  AUTH_ROLES: {
    GET_ALL: 'auth-roles'
  },
  USERS: {
    LIST_ALL: 'users',
    GET_BY_ID: 'users/',
    UPDATE: 'users/',
    STATES: 'users/states',
    CREATE: 'users',
    CHANGE_PASSWORD: 'users/change-password/',
    GET_ME: 'users/me',
    UPDATE_ME: 'users'
  },
  USER_QUOTA: {
    GET_ALL: 'user-quota'
  },
  CAMPAIGN: {
    LIST_ALL: 'campaigns',
    GET_BY_ID: 'campaigns/',
    CREATE: 'campaigns',
    UPDATE: 'campaigns/',
    UPDATE_STATE: 'campaigns/',
    GET_STATES: 'campaigns/states'
  },
  CATEGORY: {
    LIST_ALL: 'campaign-category',
    GET_BY_ID: 'campaign-category/',
    DELETE: 'campaign-category/',
    CREATE: 'campaign-category',
    UPDATE: 'campaign-category/'
  },
  QUOTE_CONFIGURATION: {
    LIST_ALL: 'quota-configuration',
    GET_BY_ID: 'quota-configuration/',
    DELETE: 'quota-configuration/',
    CREATE: 'quota-configuration',
    UPDATE: 'quota-configuration/'
  },
  ADS: {
    LIST_ALL: 'campaign-ads/',
    GET_BY_ID: 'campaign-ads/ad/',
    DELETE: 'campaign-ads/',
    CREATE: 'campaign-ads/',
    UPDATE: 'campaign-ads/'
  },
  MARKETPLACE: {
    LIST_ALL: 'marketplace',
    GET_BY_ID: 'marketplace/'
  },
  MESSAGES: {
    LIST_ALL: 'message',
    GET_BY_ID: 'message/',
    DELETE: 'message/',
    CREATE: 'message',
    UPDATE: 'message/'
  },
  MESSAGES_AD: {
    LIST_ALL: 'message-ads',
    GET_BY_ID: 'message-ads/',
    DELETE: 'message-ads/',
    CREATE: 'message-ads',
    UPDATE: 'message-ads/'
  },
  USER_SUBSCRIPTION: {
    LIST_ALL: 'user-subscriptions',
    DELETE: 'user-subscriptions/',
    ACTIVATE: 'user-subscriptions/'
  },
  DASHBOARD: {
    GET_ALL: 'dashboard'
  },
  ACCOUNT: {
    LIST_ALL: 'accounts-accounts',
    GET_BY_ID: 'accounts-accounts/',
    CREATE: 'accounts-accounts',
    UPDATE: 'accounts-accounts/'
  }
};

export const API_ENDPOINT = {
  URL_API: import.meta.env.VITE_API_URL
};
