import { useModalStore } from './stores/modal';
import { createApp, markRaw } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import { router } from './router';
import vuetify from './plugins/vuetify';
import '@/scss/style.scss';
import { PerfectScrollbarPlugin } from 'vue3-perfect-scrollbar';
// import VueApexCharts from 'vue3-apexcharts';
// import VueTablerIcons from 'vue-tabler-icons';
import print from 'vue3-print-nb';

import packageJson from '../package.json';
import { useAuthStore } from './stores/auth';
import directives from './plugins/directives';
import type { Router } from "vue-router";
import { useAdminStore } from './stores/admin';
import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import 'vue3-toastify/dist/index.css';


const { version } = packageJson;

const pinia = createPinia();
pinia.use(({ store }) => {
  store.router = markRaw(router);
});

const app = createApp(App);
app.use(router);
app.use(PerfectScrollbarPlugin);
app.use(pinia);
// app.use(VueTablerIcons);
app.use(print);
// app.use(VueApexCharts);
app.use(vuetify);
app.use(Vue3Toastify, {
  autoClose: 3000,
} as ToastContainerOptions);

directives(app);

// add a global property version
app.mixin({
  methods: {
    $hasRole(role: string | Array<string>) {
      const authStore = useAuthStore();

      // if is array use hasAnyRole
      if (Array.isArray(role)) {
        return authStore.hasAnyRole(role);
      }
      return authStore.hasRole(role);
    }
  },
  created() {
    this.$version = version;
    this.$env = import.meta.env;
  },
  mounted() {
    this.$watch(() => this.$route.query.tab, (to: any, from: any) => {
      const adminStore = useAdminStore();
      if (adminStore.tab !== to) {
        adminStore.tab = to;
      }
    })
    // get current full path from this.$route
    this.$watch(() => this.$route.fullPath, (to: any, from: any) => {
      const modalStore = useModalStore();
      modalStore.toggleModal(false);
    });

    // watch any change in the query for keys starting with filter_
    this.$watch(() => {
      // filter all query keys that start with filter_ and return string values
      return Object.keys(this.$route.query).filter(key => key.startsWith('filter_')).join();
    }, (to: any, from: any) => {

      const adminStore = useAdminStore();
      adminStore.applyFilters();
    });
  },
});


declare module "pinia" {
  export interface PiniaCustomProperties {
    router: Router;
  }
}

// add $version to type
declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $version: string;
    $env: any;
    $hasRole: (role: string | Array<string>) => boolean;
  }
}

app.mount('#app');
