import { API_ENDPOINT } from './../shared/api.services';
import axios, { type AxiosResponse } from 'axios';
import authApi from './authApi';
import { useAuthStore } from './../stores/auth';
export const ACCESS_TOKEN = 'ACCESS_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

let refreshInterval: any;
let inactivityTimer: any;

//Run every 5 minutes to refresh the token
const startTokenRefresh = () => {
  refreshInterval = setInterval(refreshToken, 5 * 60 * 1000);
};

export const getAuthenticationToken = () => {
  const auth = useAuthStore();
  return auth.getToken
};

export const getRefreshToken = () => {
  const auth = useAuthStore();
  return auth.getRefreshToken
};

const api = axios.create({
  baseURL: API_ENDPOINT.URL_API,
  headers: {
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
});

const refreshToken = async () => {
  if (!getRefreshToken()) return;
  const auth = useAuthStore();

  try {
    const { status, data } = await authApi.refresh({
      token: getAuthenticationToken(),
      refreshToken: getRefreshToken()
    } as { token: string, refreshToken: string }) as AxiosResponse<any, any>;
    if (status === 200) {

      auth.setDataUser({ ...data.data, user: data.user });
      axios.defaults.headers.common['Authorization'] = `${data.token}`;
    }
  } catch (error) {
    console.error('Failed to refresh token:', error);
    auth.logout();
  }
};

//listens to user actions and resets the inactivity timer
const addUserActivityListeners = () => {
  if (document) {
    document?.addEventListener('mousemove', resetInactivityTimer);
    document?.addEventListener('keypress', resetInactivityTimer);
    document?.addEventListener('scroll', resetInactivityTimer);
  }
};


const resetInactivityTimer = () => {
  clearTimeout(inactivityTimer);
  inactivityTimer = setTimeout(() => {
    refreshToken();
  }, 5 * 60 * 1000);
};

//initialize the inactivity check
const initializeInactivityCheck = () => {
  resetInactivityTimer();
  addUserActivityListeners();
};


api.interceptors.request.use((config: any) => {
  const token = getAuthenticationToken();
  if (token) {
    const { headers } = config;
    return {
      ...config,
      headers: {
        ...headers,
        Authorization: token,
      },
    };
  }
  return config;
});

initializeInactivityCheck();
startTokenRefresh();

export default api;
