<template>
    <v-card :title="title" >
        <v-card-text>
            {{ message }}
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn text="Cancelar" @click="close()"></v-btn>
            <v-btn text="Confirmar" type="button" color="success" @click="confirm()"></v-btn>
        </v-card-actions>
    </v-card>
</template>

<script setup lang="ts">
import { useModalStore } from '@/stores/modal';

const modalStore = useModalStore();

const emit = defineEmits(['confirm']);

const close = () => {
    modalStore.toggleModal(false);
};

const confirm = () => {
    // modalStore.toggleModal(false);
    emit('confirm');
};

const props = defineProps({
    title: String,
    message: String

});
</script>