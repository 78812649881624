import { defineStore } from 'pinia';
interface IAdminStore {
    [keyof: string]: any;
}

export interface IUserFilter {
    filter_usr_q: string,
    filter_usr_status: string
}

export interface ICampaignFilter {
    filter_cmp_q: string,
    filter_cmp_status: string,
    filter_cmp_category: string,
    filter_cmp_owner: string,
    filter_cmp_state: string,
    filter_cmp_user: string
}

export interface IUserProductFilter {
    filter_upr_q: string,
    filter_upr_user: string,
    filter_upr_category: string,
}

export interface ISubscriptionFilter {
    filter_sub_q: string,
    filter_sub_user: string,
    filter_sub_status: string,
    filter_sub_date: string,
    filter_sub_active: string,
    filter_sub_orderBy: string,
    filter_sub_sortOrder: string,
    filter_sub_page: string
}

export interface IPlansFilter {
    filter_pln_q: string,
    filter_pln_status: string,
}

export interface IProductFilter {
    filter_prd_q: string,
    filter_prd_status: string,
    filter_prd_category: string,
    filter_prd_type: string,
    filter_prd_media: string
}

export interface ICustomerFilter {
    filter_cus_month: string,
    filter_cus_customer: string
}

export interface ITypesFilter {
    filter_tpe_q: string,
    filter_tpe_status: string,
}
export interface IFilterAds {
    filter_ads_q: string,
    filter_ads_status: string,
}

export interface IAdsMessageFilter {
    filter_adm_q: string,
    filter_adm_status: string,
    filter_adm_page: string,
    filter_adm_order: string,
    filter_adm_sort: string,
    filter_adm_campaign_ad_id: string
}

export interface ICategoryFilter {
    filter_cat_q: string,
    filter_cat_status: string,
    filter_cat_limit: string,
    filter_cat_active: string,
    filter_cat_order: string,
    filter_cat_sort: string,
    filter_cat_page: string,
}

export interface IAccountFilter {
    filter_act_q: string,
    filter_act_status: string,
    filter_act_page: string,
    filter_act_limit: string,
    filter_act_order: string,
    filter_act_sort: string
}

export interface IMarketplaceFilter {
    filter_mkp_q: string,
    filter_mkp_type: string,
    filter_mkp_midia: string,
    filter_mkp_sales: string,
    filter_mkp_category: string,
    filter_mkp_tab: string,
    filter_mkp_order: string,
}

export interface IUtilsFilter {
    filter_utl_tab: string,
    filter_utl_page: string,
    filter_utl_order: string,
    filter_utl_sort: string
}
export interface IDashboard {
    filter_dsh_period: string,
    filter_dsh_metric: string,
}

export interface IAuthUserRoleFilter {
    filter_aur_page: string,
    filter_aur_order: string,
    filter_aur_sortBy: string,
    filter_aur_user: string,
    filter_aur_name: string,
}

export interface IAuthRoleFilter {
    filter_arl_page: string,
    filter_arl_order: string,
    filter_arl_sortBy: string,
    filter_arl_name: string,
}

export interface IUserQuotaFilter {
    filter_uqt_page: string,
    filter_uqt_sortBy: string,
    filter_uqt_sortOrder: string,
    filter_uqt_userId: string,
    filter_uqt_is_active: string,
    filter_uqt_date: string,
}

export enum FilterType {
    user = "usr",
    msg = "msg",
    campaign = "cmp",
    user_product = "upr",
    subscriptions = "sub",
    plans = "pln",
    products = "prd",
    customers = "cus",
    types = "tpe",
    ads = "ads",
    ads_message = "adm",
    category = "cat",
    account = "act",
    marketplace = "mkp",
    utils = "utl",
    dashboard = "dsh",
    auth_user_role = 'aur',
    auth_role = 'arl',
    user_quota = 'uqt',
}

type AdminFilters = IUserQuotaFilter | IAuthRoleFilter | IAuthUserRoleFilter | IUserFilter | ICampaignFilter | IUserProductFilter | ISubscriptionFilter | IPlansFilter | IProductFilter | ICustomerFilter | ITypesFilter | IFilterAds | IAdsMessageFilter | ICategoryFilter | IAccountFilter | IUtilsFilter | IDashboard;

export const useAdminStore = defineStore({
    id: 'admin',
    state: (): IAdminStore => ({
        isAdminPage: false,
        [FilterType.user_quota]: {
            filter_uqt_page: '',
            filter_uqt_sortBy: '',
            filter_uqt_sortOrder: '',
            filter_uqt_userId: '',
            filter_uqt_is_active: '',
            filter_uqt_date: '',
        },
        [FilterType.auth_role]: {
            filter_arl_page: '',
            filter_arl_order: '',
            filter_arl_sortBy: '',
            filter_arl_name: '',
        },
        [FilterType.auth_user_role]: {
            filter_aur_page: '',
            filter_aur_sortOrder: '',
            filter_aur_sortBy: '',
            filter_aur_user: '',
            filter_aur_name: '',
        },
        [FilterType.user]: {
            filter_usr_q: '',
            filter_usr_status: '',
        },
        [FilterType.dashboard]: {
            filter_dsh_period: '',
            filter_dsh_metric: '',
        },
        [FilterType.campaign]: {
            filter_cmp_q: '',
            filter_cmp_status: '',
            filter_cmp_category: '',
            filter_cmp_owner: '',
            filter_cmp_order: '',
            filter_cmp_sortBy: '',
            filter_cmp_page: '',
            filter_cmp_user: ''
        },
        [FilterType.user_product]: {
            filter_upr_q: '',
            filter_upr_user: '',
            filter_upr_category: '',
        },
        [FilterType.subscriptions]: {
            filter_sub_q: '',
            filter_sub_user: '',
            filter_sub_status: '',
            filter_sub_date: '',
            filter_sub_active: '',
            filter_sub_orderBy: '',
            filter_sub_sortOrder: '',
            filter_sub_page: ''
        },
        [FilterType.plans]: {
            filter_pln_q: '',
            filter_pln_status: '',
        },
        [FilterType.products]: {
            filter_prd_q: '',
            filter_prd_status: '',
            filter_prd_category: '',
            filter_prd_type: '',
            filter_prd_media: ''
        },
        [FilterType.customers]: {
            filter_cus_month: '',
            filter_cus_customer: ''
        },
        [FilterType.types]: {
            filter_tpe_q: '',
            filter_tpe_status: '',
        },
        [FilterType.ads]: {
            filter_ads_q: '',
            filter_ads_status: '',
        },
        [FilterType.ads_message]: {
            filter_adm_q: '',
            filter_adm_status: '',
            filter_adm_page: '',
            filter_adm_order: '',
            filter_adm_sort: '',
            filter_adm_campaign_ad_id: ''
        },
        [FilterType.category]: {
            filter_cat_q: '',
            filter_cat_status: '',
            filter_cat_limit: '',
            filter_cat_active: '',
            filter_cat_order: '',
            filter_cat_sort: '',
            filter_cat_page: '',
        },
        [FilterType.account]: {
            filter_act_q: '',
            filter_act_status: '',
            filter_act_page: '',
            filter_act_limit: '',
            filter_act_order: '',
            filter_act_sort: ''
        },
        [FilterType.msg]: {
            filter_msg_q: '',
            filter_msg_state: '',
            filter_msg_category: '',
            filter_msg_order: '',
            filter_msg_sort: '',
            filter_msg_page: ''
        },
        [FilterType.marketplace]: {
            filter_mkp_q: '',
            filter_mkp_type: '',
            filter_mkp_midia: '',
            filter_mkp_sales: '',
            filter_mkp_category: '',
            filter_mkp_tab: '',
            filter_mkp_order: ''
        },
        [FilterType.utils]: {
            filter_utl_tab: "",
            filter_utl_page: "",
            filter_utl_order: "",
            filter_utl_sort: ""
        },
        tab: "",
    }),
    getters: {
        copyOf() {
            return (type: FilterType) => {
                return {
                    ...this[type]
                }
            }
        },
        isProd() {
            try {
                return !window.location.href.includes('//localhost');
            } catch (error) {
                return false;
            }
        }
    },
    actions: {
        getFilterParms(type: FilterType) {
            const keys = Object.keys(this[type]);
            const cleanFilter: { [key: string]: any } = {};
            keys.forEach(key => {
                // @ts-ignore
                if (this[type][key] !== '' && this[type][key] !== null) {
                    // @ts-ignore
                    cleanFilter[key] = this[type][key];
                }
            });
            return cleanFilter;
        },
        updateTab() {
            const typesValues = Object.values(FilterType);
            // get current route query
            let { query } = this.router.currentRoute.value;
            // based on types, add the query {...type}
            typesValues.forEach(type => {
                const filter = this[type];
                // console.log(type, filter);
                query = { ...query, ...filter };
            });

            // remove empty values from query
            Object.keys(query).forEach(key => query[key] === '' && delete query[key]);

            this.router.replace({ query: query });
        },
        setFilter(type?: FilterType) {
            this.updateTab();
        },
        hasAnyFilter() {
            const typesValues = Object.values(FilterType);
            let hasFilter = false;
            typesValues.forEach(type => {
                const filter = this[type];
                const keys = Object.keys(filter);
                keys.forEach(key => {
                    // @ts-ignore
                    if (filter[key] !== '' && filter[key] !== null) {
                        hasFilter = true;
                    }
                });
            });
            return hasFilter;
        },
        applyFilters() {
            const typesValues = Object.values(FilterType);
            const parse_int = ['filter_msg_category', 'filter_mkp_type', 'filter_mkp_midia', 'filter_mkp_category'];
            // get query string from this.router.currentRoute.value.query
            const { query } = this.router.currentRoute.value;

            // if query is empty, apply reset to the store
            if (Object.keys(query).length === 0 && this.hasAnyFilter()) {
                const isAdminPage = this.isAdminPage;
                this.$reset();
                this.isAdminPage = isAdminPage;
                return;
            }

            let current: { [key: string]: any } = {};

            typesValues.forEach(type => {
                current = { ...current, ...this[type] };
            });

            // get all query keys and validate if they exist on current, if they do and content is different update store
            Object.keys(query).forEach(key => {
                // console.log(key, query[key], current[key])
                if (typeof current[key] !== 'undefined' && current[key] !== query[key]) {
                    // split key with _ and get the second part
                    const [filter, screen, k] = key.split('_');
                    // update the right key
                    // console.log(screen, key, query[key])
                    let newValue = query[key];
                    if (parse_int.includes(key)) {
                        // @ts-ignore
                        newValue = parseInt(newValue);
                    }

                    // @ts-ignore
                    this.$patch({ [screen]: { [key]: newValue } });
                }
            });
        },
        update(type: string, value: AdminFilters) {
            // @ts-ignore
            this.$patch({ [type]: value });
            this.updateTab();
        }
    },

});
