import api from './api';
import { useMessagerStore } from '@/stores/messager';
import { useAuthStore } from '@/stores/auth';


const errorHandler = (err: any) => {
  let msg = "Falha ao realizar a operação, nossa equipe já foi notificada do problema, caso o mesmo persista por favor entre em contato com o suporte."
  if (err.response && err.response.data) {
    const toast = useMessagerStore();

    // if error or message properties are found, show the error or message
    if (err.response?.data?.error || err.response?.data?.message) {
      toast.error(err.response?.data?.message || err.response?.data?.error || msg);
    } else {
      // if no error or message properties are found, show the default message
      console.error(err);
      toast.error(msg);
    }
  }

  throw err;
}

export const get = async (path: any) => api.get(`${path}`).catch(errorHandler);

export const exclude = async (path: any) => api.delete(`${path}`).catch(errorHandler);

export const post = async (path: any, body: any) => api.post(`${path}`, body).catch(errorHandler);

export const put = async (path: any, body: any) => api.put(`${path}`, body).catch(errorHandler);

export const patch = async (path: any) => api.patch(`${path}`).catch(errorHandler);
