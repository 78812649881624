import { defineStore } from 'pinia';
import { shallowRef } from 'vue';
import BaseConfirmationModal from '@/components/shared/BaseConfirmationModal.vue';


export const useModalStore = defineStore({
    id: 'modal',
    state: () => ({
        showModal: false,
        component: false,
        props: {} as any,
        attrs: {
            width: '80%'
        }
    }),
    actions: {
        close() {
            this.toggleModal(false);
        },
        toggleModal(state: boolean | undefined = undefined, component: any | undefined = undefined, props: any = {}, attrs: any = undefined) {
            // if state is defined, set it, otherwise toggle
            if (state !== undefined) {
                this.component = component;
                this.props = props;
                if (attrs !== undefined) {
                    this.attrs = attrs;
                }
                this.showModal = state;
            } else {
                this.showModal = !this.showModal;
            }
        },
        openModal(component: any, props: any = {}, attrs: any = undefined) {
            this.toggleModal(true, component, props, attrs);
        },
        confirm(title: string, message: string) {
            return new Promise((resolve, reject) => {
                this.openModal(shallowRef(BaseConfirmationModal), {
                    title,
                    message,
                    confirm: function () {
                        resolve(true);
                    }
                }, {
                    width: '40%'
                });
                const subscription = this.$subscribe((mutations, state) => {
                    if (state.showModal === false) {
                        // unsubscribe to avoid memory leaks
                        subscription();
                        // resolve with false if modal is closed to end the promise
                        resolve(false);
                    }
                })
            })
        }
    },

});
