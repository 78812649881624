
const MainRoutes = {
  path: '/',
  meta: {
    requiresAuth: true,
  },
  component: () => import('@/layouts/full/FullLayout.vue'),
  children: [
    {
      name: 'Account',
      path: '/meus-dados',
      children: [
        {
          name: 'MyAccount',
          path: '',
          component: () => import('@/views/users/MyAccount.vue'),
        },
        {
          name: 'ChangeMyAccountPassword',
          path: 'alterar-senha/:id?',
          component: () => import('@/views/users/ChangePassword.vue'),
        },
      ],
    },
    {
      name: 'ChangePassword',
      path: '/alterar-senha/:id?',
      component: () => import('@/views/users/ChangePassword.vue'),
    },
    {
      name: 'LandingPage',
      path: '/',
      component: () => import('@/views/dashboards/DefaultDashboard.vue')
    },
    {
      name: 'Default',
      path: '/dashboard/default',
      component: () => import('@/views/dashboards/DefaultDashboard.vue')
    },
  ]
};

export default MainRoutes;
