import path from 'path';

const UsersRoutes = {
    path: '/admin',
    meta: {
        requiresAuth: true,
        requiresRole: ['admin'],
        isAdminPanel: true
    },
    component: () => import('@/layouts/full/FullLayout.vue'),
    children: [
        {
            name: 'Admin',
            path: '',
            children: [
                {
                    name: 'AdminDashboard',
                    path: '',
                    component: () => import('@/views/dashboards/DefaultDashboard.vue')
                },
                {
                    name: 'AdminSentry',
                    path: 'sentry',
                    component: () => import('@/views/admin/maint/Sentry.vue')
                },
                {
                    name: 'AdminUsuarios',
                    path: 'usuarios',
                    children: [
                        {
                            name: 'AdminUsuariosList',
                            path: '',
                            component: () => import('@/views/admin/users/UsersListPage.vue')
                        },
                        {
                            name: 'AdminUsuariosForm',
                            path: 'editar/:id',
                            component: () => import('@/views/admin/users/UsersFormPage.vue')
                        }
                    ]
                },
                {
                    name: 'AdminUserSignatures',
                    path: 'assinatura',
                    children: [
                        {
                            name: 'AdminUsuariosSubscription',
                            path: '',
                            component: () => import('@/views/admin/user-subscriptions/SubscriptionsListPage.vue')
                        },
                        {
                            name: 'AdminUsuariosSubscriptionForm',
                            path: 'editar/:id',
                            component: () => import('@/views/admin/user-subscriptions/SubscriptionsFormPage.vue')
                        },
                    ]
                },
                {
                    name: 'AdminPlans',
                    path: 'planos',
                    children: [
                        {
                            name: 'AdminPlansList',
                            path: '',
                            component: () => import('@/views/admin/plans/PlansListPage.vue')
                        },
                        {
                            name: 'AdminPlansForm',
                            path: 'editar/:id',
                            component: () => import('@/views/admin/plans/PlansFormPage.vue')
                        },
                    ]
                },
                {
                    name: 'AdminProducts',
                    path: 'produtos',
                    children: [
                        {
                            name: 'AdminProductsList',
                            path: '',
                            component: () => import('@/views/admin/products/ProductsListPage.vue')
                        },
                        {
                            name: 'AdminProductsForm',
                            path: 'editar/:id',
                            component: () => import('@/views/admin/products/ProductsFormPage.vue')
                        },
                    ]
                },

                {
                    name: 'AdminCategoryProducts',
                    path: 'categorias-produto',
                    children: [
                        {
                            name: 'CategoryListPage',
                            path: '',
                            component: () => import('@/views/admin/category-products/CategoryListPage.vue')
                        },

                    ]
                },
                {
                    name: 'AdminTypesProducts',
                    path: 'tipos',
                    children: [
                        {
                            name: 'TypeListPage',
                            path: '',
                            component: () => import('@/views/admin/types-products/TypesListPage.vue')
                        },

                    ]
                },

                {
                    name: 'AdminMediaProducts',
                    path: 'midia',
                    children: [
                        {
                            name: 'MediaListPage',
                            path: '',
                            component: () => import('@/views/admin/media/MediaListPage.vue')
                        },

                    ]
                },

                {
                    name: 'AdminCampaigns',
                    path: 'campanhas',
                    children: [
                        {
                            name: 'AdminCampaignsList',
                            path: '',
                            component: () => import('@/views/admin/campaigns/CampaignListPage.vue')
                        },
                        {
                            name: 'AdminCampaignsForm',
                            path: 'editar/:id',
                            component: () => import('@/views/admin/campaigns/CampaignFormPage.vue')
                        }
                    ]
                },
                {
                    name: 'AdminCategory',
                    path: 'categorias',
                    children: [
                        {
                            name: 'AdminCategoryList',
                            path: '',
                            component: () => import('@/views/admin/category/CategoryListPage.vue')
                        }
                    ]
                },
                {
                    name: 'AdminAccounts',
                    path: 'contas-de-acesso',
                    children: [
                        {
                            name: 'AdminAccountsList',
                            path: '',
                            component: () => import('@/views/admin/accounts/AccountsListPage.vue')
                        }
                    ]
                },
                {
                    name: 'AdminMessages',
                    path: 'mensagens',
                    children: [
                        {
                            name: 'AdminMessagesList',
                            path: '',
                            component: () => import('@/views/admin/messages/MessagesListPage.vue')
                        }
                    ]
                },
                {
                    name: 'AdminQueue',
                    path: 'fila-de-envio',
                    children: [
                        {
                            name: 'AdminQueueList',
                            path: '',
                            component: () => import('@/views/admin/ads-messages/AdsMessagesListPage.vue')
                        }
                    ]
                },
            ]
        }
    ]
};

export default UsersRoutes;
