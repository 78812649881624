const MainRoutes = {
    path: '/marketplace',
    meta: {
        requiresAuth: true
    },
    component: () => import('@/layouts/full/FullLayout.vue'),
    children: [
        {
            name: 'Marketplace',
            path: '/marketplace',
            children: [
                {
                    name: 'ProductDetail',
                    path: 'detalhes/:id',
                    component: () => import('@/views/midia/ProductDetail.vue')
                },
                {
                    name: 'StoreFront',
                    path: ':tab?',
                    component: () => import('@/views/midia/StoreFront.vue'),
                },
            ]
        }
    ]
};

export default MainRoutes;
