import {
  mdiClose,
  mdiAccountCircle,
  mdiAccountCircleOutline,
  mdiCircle,
  mdiHome,
  mdiPlus,
  mdiEarth,
  mdiInstagram,
  mdiFacebook,
  mdiLinkedin,
  mdiAttachment,
  mdiLayersOutline,
  mdiThumbUpOutline,
  mdiMessageOutline,
  mdiReply,
  mdiMagnify,
  mdiAccountMultipleOutline,
  mdiAccountPlusOutline,
  mdiVideoOutline,
  mdiMessage,
  mdiBlockHelper,
  mdiTwitter,
  mdiAccountOutline,
  mdiEmailOutline,
  mdiInbox,
  mdiSendOutline,
  mdiNotebookOutline,
  mdiAlertOutline,
  mdiDeleteOutline,
  mdiStarOutline,
  mdiBookmarkOutline,
  mdiLabelOutline,
  mdiLabel,
  mdiStar,
  mdiForward,
  mdiPlusCircleOutline,
  mdiDomain,
  mdiBriefcaseOutline,
  mdiPhoneOutline,
  mdiCalendarOutline,
  mdiAlert,
  mdiAlertCircleOutline,
  mdiCartOutline,
  mdiCloseCircle,
  mdiEyeOff,
  mdiEye,
  mdiContentCopy,
  mdiCart,
  mdiMenuDown,
  mdiVideo,
  mdiAccountMultiple,
  mdiCheckboxMarkedCircleOutline,
  mdiMenuLeft,
  mdiMenuRight,
  mdiHeart,
  mdiHeartHalf,
  mdiHeartOutline,
  mdiCircleOutline,
  mdiFood,
  mdiLaptop,
  mdiSleep,
  mdiRepeat,
  mdiFormatAlignLeft,
  mdiFormatAlignCenter,
  mdiFormatAlignRight,
  mdiFormatAlignJustify,
  mdiFormatItalic,
  mdiFormatBold,
  mdiFormatUnderline,
  mdiFormatColorFill,
  mdiFormatColorText,
  mdiCheck,
  mdiCheckCircle,
  mdiPlay,
  mdiPlayCircleOutline,
  mdiBasketball,
  mdiCircleHalf,
  mdiChevronRight,
  mdiPencil,
} from '@mdi/js';

export const icons = {
  close: mdiClose,
  accountCircle: mdiAccountCircle,
  accountCircleOutline: mdiAccountCircleOutline,
  circle: mdiCircle,
  circleOutline: mdiCircleOutline,
  circleHalf: mdiCircleHalf,
  home: mdiHome,
  plus: mdiPlus,
  earth: mdiEarth,
  instagram: mdiInstagram,
  facebook: mdiFacebook,
  twitter: mdiTwitter,
  linkedin: mdiLinkedin,
  attachment: mdiAttachment,
  layerOutline: mdiLayersOutline,
  thumbUpOutline: mdiThumbUpOutline,
  messageOutline: mdiMessageOutline,
  reply: mdiReply,
  magnify: mdiMagnify,
  accountMultilineOutline: mdiAccountMultipleOutline,
  accountPlusOutline: mdiAccountPlusOutline,
  videoOutline: mdiVideoOutline,
  message: mdiMessage,
  blockHelper: mdiBlockHelper,
  accountOutline: mdiAccountOutline,
  emailOutline: mdiEmailOutline,
  inbox: mdiInbox,
  sendOutline: mdiSendOutline,
  notebookOutline: mdiNotebookOutline,
  alertOutline: mdiAlertOutline,
  deleteOutline: mdiDeleteOutline,
  starOutline: mdiStarOutline,
  star: mdiStar,
  bookmarkOutline: mdiBookmarkOutline,
  labelOutline: mdiLabelOutline,
  label: mdiLabel,
  forward: mdiForward,
  plusCircleOutline: mdiPlusCircleOutline,
  domain: mdiDomain,
  briefcaseOutline: mdiBriefcaseOutline,
  phoneOutline: mdiPhoneOutline,
  calendarOutline: mdiCalendarOutline,
  alert: mdiAlert,
  alertCircleOutline: mdiAlertCircleOutline,
  cartOutline: mdiCartOutline,
  cart: mdiCart,
  closeCircle: mdiCloseCircle,
  eyeOff: mdiEyeOff,
  eye: mdiEye,
  contentCopy: mdiContentCopy,
  menuDown: mdiMenuDown,
  video: mdiVideo,
  accountMultiple: mdiAccountMultiple,
  checkboxMarkedCircleOutline: mdiCheckboxMarkedCircleOutline,
  menuLeft: mdiMenuLeft,
  menuRight: mdiMenuRight,
  heart: mdiHeart,
  heartHalf: mdiHeartHalf,
  heartOutline: mdiHeartOutline,
  food: mdiFood,
  laptop: mdiLaptop,
  sleep: mdiSleep,
  repeat: mdiRepeat,
  formatAlignLeft: mdiFormatAlignLeft,
  formatAlignCenter: mdiFormatAlignCenter,
  formatAlignRight: mdiFormatAlignRight,
  formatAlignJustify: mdiFormatAlignJustify,
  formatItalic: mdiFormatItalic,
  formatBold: mdiFormatBold,
  formatUnderline: mdiFormatUnderline,
  formatColorFill: mdiFormatColorFill,
  formatColorText: mdiFormatColorText,
  check: mdiCheck,
  checkCircle: mdiCheckCircle,
  play: mdiPlay,
  playCircleOutline: mdiPlayCircleOutline,
  basketball: mdiBasketball,
  chevronRight: mdiChevronRight,
  pencil: mdiPencil,
};
