import type { ThemeTypes } from '@/types/themeTypes/ThemeType';

const DarkPurpleTheme: ThemeTypes = {
  name: 'DarkPurpleTheme',
  dark: true,
  colors: {
    primary: '#1e88e5',
    secondary: '#7c4dff',
    info: '#03c9d7',
    success: '#05b187',
    accent: '#fc4b6c',
    warning: '#fec90f',
    error: '#fc4b6c',
    lightprimary: '#29314f',
    lightsecondary: '#29314f',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#1565c0',
    darksecondary: '#4527a0',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#1a223f',
    surface: '#111936',
    background: '#111936',
    'on-surface-variant': '#111936',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#19203c',
    primary200: '#90caf9',
    secondary200: '#b39ddb'
  }
};

const DarkGreenTheme: ThemeTypes = {
  name: 'DarkGreenTheme',
  dark: true,
  colors: {
    primary: '#607d8b',
    secondary: '#009688',
    info: '#03c9d7',
    success: '#64ba5f',
    accent: '#d9534f',
    warning: '#f0ad4e',
    error: '#d9534f',
    lightprimary: '#0b161d',
    lightsecondary: '#0b161d',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#546e7a',
    darksecondary: '#00897b',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#0e1b23',
    surface: '#14252f',
    background: '#060d12',
    'on-surface-variant': '#14252f',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#0e1b23',
    primary200: '#b0bec5',
    secondary200: '#80cbc4'
  }
};

const DarkPinkTheme: ThemeTypes = {
  name: 'DarkPinkTheme',
  dark: true,
  colors: {
    primary: '#606d88',
    secondary: '#ec407a',
    info: '#ADB0B8',
    success: '#75B6C4',
    accent: '#FFAB91',
    warning: '#f0ad4e',
    error: '#D53D73',
    lightprimary: '#070e13',
    lightsecondary: '#070e13',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#132145',
    darksecondary: '#e42a5d',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#12172f',
    surface: '#030614',
    background: '#12172f',
    'on-surface-variant': '#12172f',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#12172f',
    primary200: '#909ab0',
    secondary200: '#f6a0bd'
  }
};

const DarkYellowTheme: ThemeTypes = {
  name: 'DarkYellowTheme',
  dark: true,
  colors: {
    primary: '#16595a',
    secondary: '#c77e23',
    info: '#03c9d7',
    success: '#00c853',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f44336',
    lightprimary: '#02131d',
    lightsecondary: '#02131d',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#135152',
    darksecondary: '#c1761f',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#010606',
    surface: '#010f17',
    background: '#010f17',
    'on-surface-variant': '#010f17',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#010606',
    primary200: '#8bacad',
    secondary200: '#e3bf91'
  }
};

const DarkSeaGreenTheme: ThemeTypes = {
  name: 'DarkSeaGreenTheme',
  dark: true,
  colors: {
    primary: '#3a5b5f',
    secondary: '#3fb0ac',
    info: '#03c9d7',
    success: '#00e676',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f44336',
    lightprimary: '#02131d',
    lightsecondary: '#02131d',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#14383d',
    darksecondary: '#39a9a5',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#010606',
    surface: '#010f17',
    background: '#010f17',
    'on-surface-variant': '#010f17',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#010606',
    primary200: '#8b9fa1',
    secondary200: '#9fd8d6'
  }
};

const DarkOliveGreenTheme: ThemeTypes = {
  name: 'DarkOliveGreenTheme',
  dark: true,
  colors: {
    primary: '#54657b',
    secondary: '#2ca58d',
    info: '#03c9d7',
    success: '#00c853',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f44336',
    lightprimary: '#071a33',
    lightsecondary: '#071a33',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#0d282c',
    darksecondary: '#279d85',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#051327',
    surface: '#030c1d',
    background: '#091f3c',
    'on-surface-variant': '#091f3c',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#051327',
    primary200: '#8591a1',
    secondary200: '#96d2c6'
  }
};

const DarkSpeechBlueTheme: ThemeTypes = {
  name: 'DarkSpeechBlueTheme',
  dark: true,
  colors: {
    primary: '#7267ef',
    secondary: '#7267ef',
    info: '#03c9d7',
    success: '#00c853',
    accent: '#FFAB91',
    warning: '#ffc107',
    error: '#f44336',
    lightprimary: '#29314f',
    lightsecondary: '#29314f',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff8e1',
    darkprimary: '#3949ab',
    darksecondary: '#3949ab',
    darkText: '#d7dcec',
    lightText: '#bdc8f0',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#1a223f',
    surface: '#111936',
    background: '#111936',
    'on-surface-variant': '#111936',
    facebook: '#4267b2',
    twitter: '#1da1f2',
    linkedin: '#0e76a8',
    gray100: '#1a223f',
    primary200: '#9fa8da',
    secondary200: '#9fa8da'
  }
};

const DarkLowadTheme: ThemeTypes = {
  name: 'DarkPinkTheme',
  dark: true,
  colors: {
    primary: '#606d88',
    secondary: '#FF0056',
    info: '#ADB0B8',
    success: '#75B6C4',
    accent: '#FFAB91',
    warning: '#f0ad4e',
    error: '#D53D73',
    lightprimary: '#070e13',
    lightsecondary: '#070e13',
    lightsuccess: '#143c33',
    lighterror: '#f9d8d8',
    lightwarning: '#fff',
    darkprimary: '#132145',
    darksecondary: '#FF0E39',
    darkText: '#d7dcec',
    lightText: '#fff',
    borderLight: '#404968',
    inputBorder: '#5f698d',
    containerBg: '#12172f',
    surface: '#030614',
    background: '#12172f',
    'on-surface-variant': '#12172f',
    facebook: '#0E5DFF',
    twitter: '#0085FF',
    linkedin: '#0e76a8',
    gray100: '#12172f',
    primary200: '#909ab0',
    secondary200: '#f6a0bd',
    lowad1: '#89d5e3',
    lowad2: '#fa4684',
  }
};

export { DarkPurpleTheme, DarkGreenTheme, DarkSpeechBlueTheme, DarkOliveGreenTheme, DarkPinkTheme, DarkYellowTheme, DarkSeaGreenTheme, DarkLowadTheme };
