import { useAuthStore } from "@/stores/auth";

export default (app: any) => {
    app.directive('has', (el: HTMLElement, binding: any, vnode: any) => {
        const authStore = useAuthStore();
        if (!authStore.hasAnyRole(binding.value)) {
            // replace HTMLElement with comment node
            const comment = document.createComment('User does not have required permission:  ' + String(binding.value));
            Object.defineProperty(comment, 'setAttribute', {
                value: () => undefined,
            });
            vnode.elm = comment;
            vnode.text = ' ';
            vnode.isComment = true;
            vnode.context = undefined;
            vnode.tag = undefined;

            if (vnode.data) {
                vnode.data.directives = undefined;
            }

            if (vnode.componentInstance) {
                vnode.componentInstance.$el = comment;
            }

            if (el.parentNode) {
                el.parentNode.replaceChild(comment, el);
            }
        }
    });


}