import { Position } from '@braks/vue-flow';
import { defineStore } from 'pinia';
import { toast } from 'vue3-toastify';

export const useMessagerStore = defineStore({
    id: 'messager',
    state: () => ({
        messages: [] as { id: number; text: string; type: string; show: boolean }[],
        id: -1 as number,
        timeout: 3000 as number,
    }),
    getters: {
        newID(): number {
            ++this.id;
            return this.id;
        }
    },
    actions: {
        message(text: string, type: string) {
            const id = this.newID;
            // this.messages.push({ id, text, type, show: true });
            setTimeout(() => {
                toast(text, {
                    autoClose: this.timeout,
                    position: toast.POSITION.BOTTOM_CENTER,
                    transition: toast.TRANSITIONS.SLIDE,
                    type: type as any,
                    theme: 'dark',
                });
            }, 500)
        },
        info(text: string) {
            this.message(text, 'primary');
        },
        success(text: string) {
            this.message(text, 'success');
        },
        warning(text: string) {
            this.message(text, 'warning');
        },
        error(text: string) {
            this.message(text, 'danger');
        },
    },

});
