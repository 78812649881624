<template>
  <v-icon :small="size" :icon="iconSelected"></v-icon>
</template>

<script setup lang="ts">
import { mdiDelete, mdiKey, mdiTrashCan, mdiCancel, mdiContentSave, mdiPlus, mdiPlusLockOpen, mdiLockOpen, mdiChevronLeft, mdiChevronRight, mdiAccountCircle, mdiWallet } from '@mdi/js';

const props = defineProps(['icon', 'small']);

const size = props.small ? true : false;

const icons: { [key: string]: any } = {
  "mdi-delete": mdiDelete,
  "mdi-key": mdiKey,
  "mdi-trash-can": mdiTrashCan,
  "mdi-cancel": mdiCancel,
  "mdi-content-save": mdiContentSave,
  "mdi-plus": mdiPlus,
  'mdi-plus-lock-open': mdiPlusLockOpen,
  'mdi-lock-open': mdiLockOpen,
  'mdi-chevron-left': mdiChevronLeft,
  'mdi-chevron-right': mdiChevronRight,
  'mdi-account-circle': mdiAccountCircle,
  'mdi-wallet': mdiWallet,
};
if (!icons[props.icon]) {
  throw new Error(`Icon ${props.icon} not found`);
}
const iconSelected = icons[props.icon];
</script>