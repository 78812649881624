import VIconPlus from "@/components/components/shared/VIconPlus.vue";
import hasRole from "./hasRole";
import VIconPencil from "@/components/components/shared/VIconPencil.vue";
//import VIconContentSave from "@/components/components/shared/VIconContentSave.vue";
import VIconHandler from "@/components/components/shared/VIconHandler.vue";


export default (app: any) => {
    hasRole(app);

    app.component('v-icon-plus', VIconPlus);
    app.component('v-icon-pencil', VIconPencil);
    //app.component('v-icon-content-save', VIconContentSave);
    app.component('v-icon-handler', VIconHandler);
};