import { defineStore } from 'pinia';
import { router } from '@/router';
import authApi from '@/services/authApi';
import { jwtDecode } from "jwt-decode";
import { useMessagerStore } from '@/stores/messager';
import type { AxiosResponse } from 'axios';

export const useAuthStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    /* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    user: JSON.parse(localStorage.getItem('user')),
    accessToken: localStorage.getItem('ACCESS_TOKEN') || null,
    refreshToken: localStorage.getItem('REFRESH_TOKEN') || null,
    returnUrl: null,
  }),
  getters: {
    getToken(): any {
      return this.accessToken
    },
    getRefreshToken(): any {
      return this.refreshToken
    }
  },
  actions: {
    hasRole(role: string | Array<string>) {
      if (!this.user?.roles) return false;

      if (Array.isArray(role)) {
        return this.hasAnyRole(role);
      }
      return this.user && this.user.roles.some((r: string) => r === role);
    },
    hasAnyRole(roles: string[]) {
      // if roles are empty or is an empty array return true
      if (!roles || roles.length === 0) return true;
      if (!this.user?.roles) return false;
      return this.user && this.user.roles.some((r: string) => roles.includes(r));
    },
    async login(username: string, password: string) {
      //const user = await fetchWrapper.post(`${baseUrl}/authenticate`, { username, password });

      try {
        const response = await authApi.login({ email: username, password }) as AxiosResponse;
        const { data, user } = response.data;
        data['user'] = user
        // store user details and jwt in local storage to keep user logged in between page refreshes
        this.setUserLocalStorage(data);

      } catch (e) {
      }
    },

    setUserLocalStorage(user: any) {
      this.setDataUser(user);
      // redirect to previous url or default to home page
      if (this.user.usingTemporaryPassword) {
        router.push({
          name: 'ChangePassword'
        });
      } else {
        router.push(this.returnUrl || '/');
      }
    },

    setDataUser(data: any) {
      //this.user.usingTemporaryPassword = true;
      localStorage.setItem('user', JSON.stringify(data));
      localStorage.setItem('ACCESS_TOKEN', data.token);
      localStorage.setItem('REFRESH_TOKEN', data.refreshToken);
      this.user = data;
      this.accessToken = data.token;
      this.refreshToken = data.refreshToken;

    },
    logout() {
      this.user = null;
      this.clear();
      router.push('/login');
    },


    async resetPassword(email: string) {

      await authApi.reset({ email: email }).then((resp: any) => {
        router.push({ name: 'Email enviado', query: { email: email } });
        useMessagerStore().success(resp.data.message);
      })
    },


    async changePassword(payload: { password: string, newPassword: string }) {
      try {
        const response = await authApi.changePassword(payload) as AxiosResponse;
        const { data, user } = response.data;
        data['user'] = user
        this.setDataUser(data);
        useMessagerStore().success("Senha alterada com sucesso!");
        return response;
      } catch (e: any) {
        return false
      }
    },

    async register(data: { name: string, email: string, password: string }) {

      await authApi.register(data).then((resp: any) => {
        const { data } = resp;
        this.setUserLocalStorage(data.data.token);
        useMessagerStore().success("Registro efetuado com sucesso!")
        return resp
      })
    },


    isTokenValid() {
      const token = localStorage.getItem('ACCESS_TOKEN');

      if (!token) return false;

      try {
        const { exp } = jwtDecode(token);
        // Se o token não tiver uma data de expiração, ele é inválido
        if (!exp) return false;

        // Verificar se o token ainda não expirou
        return exp * 1000 > Date.now();
      } catch (error) {

        return false; // Se houver algum erro, considere o token inválido
      }
    },


    clear() {
      localStorage.removeItem('user');
      localStorage.removeItem('ACCESS_TOKEN');
      localStorage.removeItem('REFRESH_TOKEN');
      this.accessToken = null;
      this.refreshToken = null;
      this.user = null;
    }
  }
});
