const UsersRoutes = {
    path: '/usuarios',
    meta: {
        requiresAuth: true
    },
    component: () => import('@/layouts/full/FullLayout.vue'),
    children: [
        {
            name: 'Usuários',
            path: '/usuarios',
            children: [
                {
                    name: 'UsersList',
                    path: '',
                    component: () => import('@/views/users/UsersListPage.vue'),
                },
                {
                    name: 'UsuariosForm',
                    path: 'editar/:id',
                    component: () => import('@/views/users/UsersFormPage.vue')
                },
            ]
        }
    ]
};

export default UsersRoutes;
