import { ENDPOINTS } from './../shared/api.services';
import { post } from './http';

export default {
  login: async (payload: { email: string, password: string }) => await post(ENDPOINTS.AUTH.LOGIN, payload),
  reset: async (payload: { email: string }) => await post(ENDPOINTS.AUTH.RESET, payload),
  changePassword: async (payload: { password: string, newPassword: string }) => await post(ENDPOINTS.AUTH.CHANGE_PASSWORD, payload),
  register: async (payload: { name: string, email: string, password: string }) => await post(ENDPOINTS.AUTH.REGISTER, payload),
  refresh: async (payload: { token: string }) => await post(ENDPOINTS.AUTH.REFRESH_TOKEN, payload),
};
