const AuthRoutes = {
  path: '/',
  component: () => import('@/layouts/blank/BlankLayout.vue'),
  meta: {
    requiresAuth: false
  },
  children: [
    {
      name: 'Login',
      path: 'login',
      component: () => import('@/views/authentication/auth/LoginPage.vue')
    },
    {
      name: 'Criar Conta',
      path: 'criar-conta',
      component: () => import('@/views/authentication/auth/RegisterPage.vue')
    },
    {
      name: 'Esqueceu a senha',
      path: 'esqueceu-a-senha',
      component: () => import('@/views/authentication/auth/ForgetPage.vue'),
    },
    {
      name: 'Email enviado',
      path: 'email-enviado',
      component: () => import('@/views/authentication/auth/EmailSentPage.vue')
    },
    {
      name: 'Error 404',
      path: '/pages/error',
      component: () => import('@/views/pages/maintenance/error/Error404Page.vue')
    }
  ]
};

export default AuthRoutes;
