const MainRoutes = {
    path: '/campanhas',
    meta: {
        requiresAuth: true
    },
    component: () => import('@/layouts/full/FullLayout.vue'),
    children: [
        {
            name: 'Campaign',
            path: '/campanhas',
            children: [
                {
                    name: 'CampaignList',
                    path: '',
                    component: () => import('@/views/campaign/CampaignList.vue'),
                },
                {
                    name: 'CampaignForm',
                    path: 'editar/:id',
                    component: () => import('@/views/campaign/CampaignFormPage.vue')
                },
            ]
        }
    ]
};

export default MainRoutes;
